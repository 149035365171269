<template>
  <div>
    <div class="head-content">
      <div class="head-item" style="width: 38%">
        <div class="head-text">考试合格率</div>
        <div class="item-content">
          <img src="@/assets/images/pass.png" alt=""/>
          <div class="item-right">
            <span class="right-text">{{ number }}</span
            >%
            <div>考试合格率</div>
          </div>
        </div>
      </div>
      <div class="head-item" style="width: 60%">
        <div class="head-text">
          未完成考试及未通过考试人员名单（{{ count }} / {{ totalCount }}人）
        </div>
        <div class="item-content">
          <img src="@/assets/images/below.png" alt=""/>
          <div class="item-person">
            <div class="person" v-for="(item,index) in names" :key="index">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pictureBox">
      <div class="head-text">补充培训记录</div>
      <avue-form
        :option="option"
        v-model="form"
        :upload-preview="rowView"
        :upload-after="uploadAfter"
        :upload-delete="uploadDelete"
      ></avue-form>
    </div>
    <CommonDialog v-if="showFileDialog" :showConfirmBtn="false" width="70%" dialogTitle="附件预览"
                  @cancel="showFileDialog = false">
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
    </CommonDialog>
    <video
      ref="video"
      id="myVideo"
      :src="videoUrl"
      crossOrigin="anonymous"
      style="display: none"
    ></video>
    <canvas
      id="myCanvas"
      style="display: none"
      crossOrigin="anonymous"
    ></canvas>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {
  getThreeTrainImage,
  nowTrainSaveImage,
  nowTrainDeleteImage,
  trainGroupSceneSubmit,
} from "@/api/onTheJobTraining/training";
import {calculateQualification} from "@/api/training/recording";
import website from "@/config/website";
import {putFile} from "@/api/system/user";
import CommonDialog from "@/components/CommonDialog";

export default {
  data() {
    return {
      map: new Map(),
      videoUrl: "",
      cover: "",
      showFileDialog: false,
      fileUrl: "",
      number: 0,
      count: 0,
      totalCount: 0,
      siteTrainingId: "",
      recordingId: "",
      form: {
        imgUrl: [],
      },
      names: [],
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 0,
        column: [
          {
            label: "",
            prop: "imgUrl",
            listType: "picture-card",
            type: "upload",
            accept: 'image/png, image/jpeg',
            span: 24,
            limit: 9,
            propsHttp: {
              res: "data",
              url: "link",
            },
            tip: '只能上传jpg/png的9张图片',
            click(r, s) {
              if (r.value.length >= 9) {
                return this.$message.warning("最多支持上传9张图片！");
              }
            },
            action: "api/sinoma-resource/oss/endpoint/put-file",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["themeColor"]),
  },
  components: {CommonDialog},
  mounted() {
  },
  methods: {
    base64ToFile(base64Data, filename) {
      // 将base64的数据部分提取出来
      const parts = base64Data.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);

      // 将原始数据转换为Uint8Array
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      // 使用Blob和提取出的数据内容创建一个新的Blob对象
      const blob = new Blob([uInt8Array], {type: contentType});

      // 创建一个指向Blob对象的URL，并使用这个URL创建一个File对象
      const blobUrl = URL.createObjectURL(blob);
      const file = new File([blob], filename, {type: contentType});

      // 返回File对象
      return file;
    },
    qualificationRate() {
      calculateQualification(this.siteTrainingId).then((res) => {
        if (res.data.code == 200) {
          this.number = res.data.data.qualified;
          this.count = (res.data.data.totalCount - res.data.data.count);
          this.totalCount = res.data.data.totalCount;
          this.names = res.data.data.name ? res.data.data.name.split(',') : []
        }
      });
      this.form.imgUrl = [];
      this.map = new Map();
      // 回显补充培训记录（照片、视频）
      getThreeTrainImage(this.siteTrainingId).then((res) => {
        if (res.data.data.length != 0) {
          this.form.imgUrl = [];
          res.data.data.forEach((item) => {
            this.map.set(item.id, item.image);
            if (item.cover) {
              //视频默认使用的图片
              this.form.imgUrl.push({
                label: item.id,
                value: item.cover,
              });
            } else {
              this.form.imgUrl.push({
                label: item.id,
                value: item.image,
              });
            }
          });
        }
      });
    },
    rowView(file, column, done) {
      let url = this.map.get(file.name);
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL + "?url=" + encodeURIComponent(Base64.encode(url));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    uploadAfter(res, done, loading, column) {
      this.videoUrl = res.link;
      let that = this;
      that.$nextTick(() => {
        let extension = that.videoUrl.split(".").pop();
        if (
          extension == "mp4" ||
          extension == "avi" ||
          extension == "mkv" ||
          extension == "mov" ||
          extension == "wmv" ||
          extension == "flv" ||
          extension == "mpeg"
        ) {
          //如果上传的是视频,需要截取封面
          const video = document.getElementById("myVideo");
          const canvas = document.getElementById("myCanvas");
          const ctx = canvas.getContext("2d");
          video.addEventListener("loadeddata", function () {
            video.currentTime = 2;
            setTimeout(() => {
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
              const imageURL = canvas.toDataURL("image/png");
              const formData = new FormData();
              // console.log("进行输出-----------1",imageURL)
              formData.append(
                "file",
                that.base64ToFile(imageURL, Date.now() + ".png")
              );
              putFile(formData).then((res) => {
                that.cover = res.data.data.link;
                nowTrainSaveImage({
                  image: that.videoUrl,
                  recordingId: that.recordingId,
                  cover: that.cover,
                }).then((res) => {
                  that.$message.success("上传成功");
                  that.qualificationRate();
                  done();
                });
              });
            }, 2000);
          });
        } else {
          nowTrainSaveImage({
            image: that.videoUrl,
            recordingId: that.recordingId,
            cover: "",
          }).then((res) => {
            that.$message.success("上传成功");
            that.qualificationRate();
            done();
          });
        }
      });
    },
    uploadDelete(file, column) {
      let boolean = file.name.indexOf("upload") != -1;
      if (!boolean) {
        nowTrainDeleteImage(file.name).then((res) => {
          this.$message.success("删除成功");
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.head-content {
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: space-between;

  .head-text {
    font-size: 22px;
    color: #3d3d3d;
    padding: 5px 0 5px 30px;
  }

  .item-content {
    padding: 30px;
    border: 1px solid #c0ccda;
    border-radius: 10px;
    display: flex;
    align-items: center;
    background: #fbfdff;

    .item-right {
      margin-left: 65px;
      font-size: 16px;
      color: #3d3d3d;

      .right-text {
        font-size: 72px;
        color: #3d3d3d;
      }
    }

    .item-person {
      margin-left: 65px;
      display: flex;
      flex-wrap: wrap;
      max-height: 150px;
      overflow-y: auto;

      .person {
        padding: 4px 10px;
        border-radius: 20px;
        background: #D8D8D8;
        margin: 0 24px 24px 0;
      }
    }
  }
}

.title {
  width: 70%;
  text-align: left;
  height: 50px;
  line-height: 50px;
  color: #333333;
  font-size: 28px;
  margin-left: 30%;
  padding: 12px 0;
}

.qualified {
  width: 70%;
  text-align: left;
  height: 50px;
  line-height: 50px;
  color: #333333;
  font-size: 34px;
  font-weight: bold;
  margin-left: 30%;
  padding: 12px 0;
}

.pictureBox {
  padding: 12px;
  background-color: #ffffff;

  .head-text {
    font-size: 22px;
    color: #3d3d3d;
    padding: 5px 0 5px 30px;
  }
}
</style>
